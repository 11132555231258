<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="12">
            <a-form-item label="交易订单号">
              <a-input
                :allowClear="true" 
                :maxLength="240" 
                placeholder="请输入交易订单号进行搜索"
                v-model="queryParam.id"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="下单时间" >
              <a-range-picker
                style="width: 100%"
                v-model="queryParam.pickerTimeRange"
                :default-value="[moment(today, dateFormat), moment(today, dateFormat)]"
                :format="dateFormat"
                @change="onDateChange"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="是否使用优惠券">
              <a-select  
                   style="width: 100%" 
                   placeholder="是否使用优惠券"
                   v-model="queryParam.useCoupon"
                   :allowClear="true" >
                  <a-select-option  value="1">
                    是
                  </a-select-option> 
                  <a-select-option  value="0">
                    否
                  </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="订单状态">
              <a-select  
                   style="width: 100%" 
                   placeholder="请选择订单状态"
                   v-model="queryParam.status"
                   :allowClear="true" >
                  <a-select-option  value="2">
                    支付成功
                  </a-select-option> 
                  <a-select-option  value="3">
                    已退款
                  </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span
              style="float: left; overflow: hidden"
              class="table-page-search-submitButtons"
            >
              <a-button type="primary" @click="searchQuery" icon="search"
                >查询</a-button
              >
              <a-button
                type="primary"
                @click="searchReset"
                icon="reload"
                style="margin-left: 8px"
                >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">

    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
         <a-descriptions title="数据看板" :column="5" >
            <a-descriptions-item label="已支付金额">
              {{ otherDataSource.actualMoney  }} 元
            </a-descriptions-item>
            <a-descriptions-item label="已支付订单数">
              {{ otherDataSource.actualNum }} 个
            </a-descriptions-item>
            <a-descriptions-item label="退款金额">
              {{ otherDataSource.refundMoney}} 元
            </a-descriptions-item>
            <a-descriptions-item label="退款订单数">
              {{ otherDataSource.refundNum}} 个
            </a-descriptions-item>
            <a-descriptions-item label="实际收益">
              <span style="color: red;">{{ otherDataSource.realMoney }} 元</span>
            </a-descriptions-item>
          </a-descriptions>
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:true}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
         :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">

       
          <template slot="actualAmount" slot-scope="text, record">
             {{ text }} 元
             <div v-if="record.discountAmount > 0" style="color: red;">(优惠{{ record.discountAmount }} 元)</div>
          </template>
          <template slot="refundMoney" slot-scope="text, record">
             {{ text }} 元
          </template>

          
          

         <template slot="useClick" slot-scope="text, record">
            <a-tag color="#5ac725" v-if="record.useClick == 1">
                回调成功
            </a-tag>
            <a-tag color="#FF2D2D" v-else-if="record.useClick == 2">
                回调失败
            </a-tag>
            <a-tag color="#3c9cff" v-else-if="record.useClick == 0">
                暂未回调
            </a-tag>
          </template>

          <template slot="status" slot-scope="text, record">
            <a-tag color="#5ac725" v-if="text == 2">
                支付成功
            </a-tag>
            <a-tag color="#FF2D2D" v-else-if="text == 3">
                已退款
            </a-tag>
          </template>
          

        <span slot="action" slot-scope="text, record">
          <a-popconfirm title="确定回传上报吗?" @confirm="() => pedigreeCallBackClick(record)">
              <a>回传上报</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="refundOrder(record)" v-if="otherDataSource.code">退款</a>
        </span>
      </a-table>
    </div>

    <a-modal
      title="退款"
      :width="800"
      :visible="modalVisible"
      :confirmLoading="confirmLoading"
      switchFullscreen
      @ok="refundMoneyOk"
      @cancel="modalVisible = false"
      cancelText="关闭">
      <a-form  :form="form" :label-col="{ span: 17 }" :wrapper-col="{ span: 7}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="用户实际付款金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
                   {{ record.actualAmount }} 元
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="设置退款金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input-number :max="999999" :min="0.01" :precision="2"
                       v-decorator="['refundMoney', { rules: [{ required: true, message: '设置设置退款金额!' }] }]"
                       placeholder="设置设置退款金额" style="width: 100%" />
                </a-form-item>
              </a-col>
            </a-row>
      </a-form>
    </a-modal>
  </a-card>
</template>
 
<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { CustomerShopMixin } from '@/mixins/CustomerShopMixin'
  import { getAction, postAction,uploadAction } from '@/api/manage'
  import moment from 'moment';
  export default {
    name: 'List',
    mixins:[CustomerShopMixin, mixinDevice],
    components: {
    },
    data () {
      return {
        description: 'List管理页面',
        dateFormat:'YYYY-MM-DD',
        modalVisible: false,
        confirmLoading: false,
        form: this.$form.createForm(this, { name: 'coordinated' }),
        refundParam: {},
        record:{},
        today:"2024-11-11",
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        // 表头
        columns: [
			{
			  title: '交易订单号',
			  dataIndex: 'order_no',
			  align:"center",
			  ellipsis: true,
			  width:180,
			},
          {
            title: '下单时间',
            dataIndex: 'orderTime',
            align:"center",
            ellipsis: true,
            width:180,
          }, {
            title: '订单状态',
            dataIndex: 'code',
            scopedSlots: { customRender: 'status' },
            align:"center",
            ellipsis: true,
            width:180,
          },
          {
            title: '支付金额',
            dataIndex: 'preferential_price',
            align:"center",
            ellipsis: true,
            width:160,
          },
          // {
          //   title: '退款金额',
          //   dataIndex: 'refundMoney',
          //   scopedSlots: { customRender: 'refundMoney' },
          //   align:"center",
          //   ellipsis: true,
          //   width:140,
          // },
          {
            title: '姓名',
            dataIndex: 'user_name',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '城市',
            dataIndex: 'city',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '专业',
            dataIndex: 'major',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '项目id',
            dataIndex: 'projectid',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '广告id',
            dataIndex: 'promotionid',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '回调标识',
            dataIndex: 'clickid',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            fixed:"right",
            width:147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/shop/web/electronTools/getSheGongOrderList",
        },

      }
    },
    created() {
      let today = moment().format('YYYY-MM-DD');
      this.today = today;
      this.queryParam.startTime = today;
      this.queryParam.endTime = today;
    },
    computed: {
    
    },
    methods: {
       moment,
       addRotation(){
          this.$refs.modalForm.add();
       },
       modalFormOk(){

       },
       onDateChange: function (value, dateString) {
          this.queryParam.startTime=dateString[0];
          this.queryParam.endTime=dateString[1];
       },
       refundOrder(record){
          let that = this;
          let param = {
             id: record.id,
             status: 3
          }
          this.refundParam = param;
          this.record = record;
          this.$nextTick(() => {
            setTimeout(() => {
              this.form.setFieldsValue({
                 refundMoney: record.preferential_price,
              })
            })
        });
          this.modalVisible = true
       },
       refundMoneyOk(){
          let that = this;
          this.confirmLoading = true
          let param = this.refundParam;
          this.form.validateFields((err, values) => {
          if (!err) {
                param.refundMoney = values.refundMoney
                postAction("/shop/web/electronTools/refundSheGongOrder",param).then((res)=>{
                if(res.code == 200){
                  that.$message.success("退款成功");
                  that.modalVisible = false
                  that.loadData();
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                that.confirmLoading = false
              })
            }
          })
          
       },
       pedigreeCallBackClick(record){
          let that = this;
          this.loading = true
          let param = {
             clickId: record.clickid
          }
          postAction("/shop/web/electronTools/sheGongCallBackClick",param).then((res)=>{
            if(res.code == 200){
              that.$message.success("操作成功");
              that.loadData();
            }else{
              that.$message.error(res.message);
            }
          }).finally(() => {
            this.loading = false
          })
       }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>